<script lang="ts" setup>
/**
 * This component is the page component responsible for rendering blog home page (/blog).
 * This template is similar to g pages template, but has floating action button / round modal turned off
 * and logo only header.
 * It renders the content to index.html under the blog directory.
 *
 * @see: https://content.nuxt.com/components/content-doc#slots
 */
definePageMeta({
  layout: 'basic',
})

const route = useRoute()
// Note: entry-specific front matter is specified via the /content entry.
// The canonical link depends on the route, rather than the entry, so we specify
// it here.
useHead({
  link: [
    {
      rel: 'canonical',
      href: `https://grove.co/blog`,
    },
  ],
})

// Contain the components that should always be full width, listed by contentTypeId or slug.
const FULL_WIDTH_COMPONENTS = [
  'productLaunchHero',
  'imageCtaHero',
  'carouselQuote',
]

const getSpanBindings = (contentTypeId: String) => {
  const renderFullWidth = FULL_WIDTH_COMPONENTS.includes(contentTypeId)
  return {
    small: renderFullWidth,
    medium: renderFullWidth,
    large: renderFullWidth,
  }
}
</script>

<template>
  <NuxtLayout>
    <ContentDoc :path="route.path">
      <template #default="{ doc }">
        <div class="BlogPage">
          <SkyPage class="BlogPage_Content">
            <SkyFullSpan
              v-for="entry in doc.widgets"
              :key="entry.id"
              v-bind="getSpanBindings(entry.contentTypeId)"
            >
              <ContentfulContent :entries="[entry]" />
            </SkyFullSpan>
          </SkyPage>
        </div>
      </template>
    </ContentDoc>
  </NuxtLayout>
</template>

<style lang="scss">
.BlogPage {
  max-width: 100vw;
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin-bottom: var(--spacing-12x);

  &_Content {
    row-gap: 0;
  }
}
</style>
